<template>
  <div class="map-chart" :ref="refName"></div>
</template>

<script>
import { chinaObj } from "@/util/china.js";
export default {
  props: {
    refName: {
      type: String,
      default: "refName" + Math.random(),
    },
    dataList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      myChart: null,
      geoCoordMap: {
        台湾省: [121.5135, 25.0308],
        黑龙江省: [127.9688, 45.368],
        内蒙古自治区: [110.3467, 41.4899],
        吉林省: [125.8154, 44.2584],
        北京市: [116.4551, 40.2539],
        辽宁省: [123.1238, 42.1216],
        河北省: [114.4995, 38.1006],
        天津市: [117.4219, 39.4189],
        山西省: [112.3352, 37.9413],
        陕西省: [109.1162, 34.2004],
        甘肃省: [103.5901, 36.3043],
        宁夏回族自治区: [106.3586, 38.1775],
        青海省: [101.4038, 36.8207],
        新疆维吾尔自治区: [87.9236, 43.5883],
        西藏自治区: [91.11, 29.97],
        四川省: [103.9526, 30.7617],
        重庆市: [108.384366, 30.439702],
        山东省: [117.1582, 36.8701],
        河南省: [113.4668, 34.6234],
        江苏省: [118.8062, 31.9208],
        安徽省: [117.29, 32.0581],
        湖北省: [114.3896, 30.6628],
        浙江市: [119.5313, 29.8773],
        福建省: [119.4543, 25.9222],
        江西省: [116.0046, 28.6633],
        湖南省: [113.0823, 28.2568],
        贵州省: [106.6992, 26.7682],
        云南省: [102.9199, 25.4663],
        广东市: [113.12244, 23.009505],
        广西壮族自治区: [108.479, 23.1152],
        海南省: [110.3893, 19.8516],
        上海市: [121.4648, 31.2891],
      },
    };
  },
  mounted() {
    this.echartsMap();
  },
  methods: {
    echartsMap() {
      this.myChart = this.$echarts.init(this.$refs[this.refName]);
      // console.log(chinaObj, "chinaObj");
      const that = this;
      this.$echarts.registerMap("china", chinaObj);
      let dataList = [];
      let clickName = [];
      this.dataList.forEach((item) => {
        dataList.push({
          name: item.province,
          value: item.counts,
        });
        clickName.push(item.province);
      });
      const convertData = function (data) {
        let res = [];
        for (let i = 0; i < data.length; i++) {
          let geoCoord = that.geoCoordMap[data[i].name];
          if (geoCoord) {
            res.push({
              name: data[i].name,
              value: geoCoord.concat(data[i].value),
              itemStyle: {
                color: "#f00", //标志颜色
              },
            });
          }
        }
        return res;
      };
      const option = {
        tooltip: {
          trigger: "item",
          show: false,
          enterable: true,
          textStyle: {
            fontSize: 20,
            color: "#fff",
          },
          backgroundColor: "#5490db",
          formatter: "{b}<br />{c}",
        },
        visualMap: {
          type: "piecewise",
          show: false,
          pieces: [
            { gt: 0, color: "#EDD72B" }, // 表示 value 等于 123 的情况。
            { lte: 0, color: "#80C5EE" }, // 不指定 min，表示 min 为无限大（-Infinity）。
          ],
        },
        geo: [
          {
            map: "china",
            roam: false, // 是否允许缩放
            zoom: 1.2, // 默认显示级别

            // 地图上文字
            label: {
              show: true, // 是否显示标签
              textStyle: {
                color: "#fff",
                fontSize: 12,
              },
              emphasis: {
                textStyle: {
                  color: "#fff",
                },
              },
            },
            itemStyle: {
              areaColor: "#80C5EE",
            },
            emphasis: {
              itemStyle: {
                areaColor: "#0160AD",
              },
              label: {
                show: 0,
                color: "#fff",
              },
            },
            zlevel: 1,
          },
        ],
        series: [
          {
            geoIndex: 0,
            type: "map",
            data: dataList,
            itemStyle: {
              areaColor: "#EDD72B",
            },
            emphasis: {
              itemStyle: {
                areaColor: "#EDD72B",
              },
              label: {
                show: 0,
                color: "#fff",
              },
            },
            zlevel: 2,
          },
          {
            type: "scatter",
            coordinateSystem: "geo",
            geoIndex: 0,
            zlevel: 3,
            symbol: "pin",
            symbolSize: [50, 50],
            label: {
              normal: {
                show: true,
                // position: [20, 5],
                textStyle: {
                  color: "#fff",
                  fontSize: 12,
                },
                formatter(params) {
                  return params.data.value[2];
                },
              },
            },
            // symbol: `image://${require("../../assets/qi.png")}`,
            // symbolSize: [50, 30],
            silent: true,
            data: convertData(dataList),
            // itemStyle: {
            //   normal: {
            //     color: "#f00", //标志颜色
            //   },
            // },
          },
        ],
      };
      // this.charts 为 echarts 实例。解决事件冒泡（点击触发两次事件）的问题
      this.myChart.off("click");
      this.myChart.on("click", function (params) {
        if (clickName.includes(params.name)) {
          that.$emit("selectProvince", params.name);
        }
      });
      this.myChart.clear();
      this.myChart.setOption(option);
    },
  },
  watch: {
    dataList: {
      handler() {
        this.myChart.clear();
        this.echartsMap();
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
.map-chart {
  width: 100%;
  height: 100%;
  margin: auto;
}
</style>