<template>
  <!-- 网点分布 -->
  <div>
    <h2>网点分布</h2>
    <div class="map-box" @click="isShow = false">
      <img class="logo-box" src="@/assets/logo.jpg" alt="" />
      <mapCharts
        :dataList="areaList"
        @selectProvince="selectProvince"
      ></mapCharts>
      <div class="mask-box" v-if="isShow">
        <div
          class="website-item"
          v-for="(item, index) in websiteList"
          :key="index"
        >
          <p>{{ item.areaName || "--" }}</p>
          <div class="item1">
            <div class="label label-gray">咨询热线：</div>
            <div>{{ item.phoneNum || "--" }}</div>
          </div>
          <div class="item1">
            <div class="label">详细地址：</div>
            <div>{{ item.address || "--" }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getAreaSitePage, getAreaSiteProvinceCount } from "@/api";
import mapCharts from "@/components/map";
export default {
  // 网点分布
  name: "distribution",
  components: {
    mapCharts,
  },
  data() {
    return {
      areaList: [],
      province: "",
      websiteList: [],
      isShow: false,
    };
  },
  mounted() {
    // this.$loginDialog();
    this.getAreaSiteProvinceCountApi();
  },
  methods: {
    // 查询所有省市网点数
    getAreaSiteProvinceCountApi() {
      getAreaSiteProvinceCount().then((res) => {
        if (res.code == 1) {
          // let resData = res.data || {};
          this.areaList = res.data || [];
        }
      });
    },
    // 选中的省市
    selectProvince(val) {
      this.province = val || "";
      this.isShow = false;
      this.getAreaSitePageApi();
    },
    // 查询选中省市具体网点
    getAreaSitePageApi() {
      getAreaSitePage({
        current: 1,
        size: 500,
        province: this.province,
      }).then((res) => {
        let resData = res.data || {};
        this.websiteList = resData.records || [];
        this.isShow = true;
      });
    },
  },
};
</script>
<style lang="less" scoped>
#container {
  height: 600px;
}
h2 {
  text-align: center;
  margin-bottom: 50px;
}
.map-box {
  width: 100%;
  height: 696px;
  margin-bottom: 50px;
  position: relative;
  .mask-box {
    position: absolute;
    left: 10;
    top: 0;
    width: 300px;
    min-height: 100px;
    max-height: 400px;
    overflow-y: auto;
    // background: rgba(0, 0, 0, 0.5);
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    .website-item {
      width: 90%;
      background: #fff;
      margin: 10px auto;
      p {
        color: #1ab394;
      }
      .item1 {
        margin: 10px 0;
        display: flex;
        justify-content: flex-start;
        align-content: center;
      }
      .label {
        width: 74px;
        &.label-gray {
          color: #999999;
        }
      }
    }
  }
  .logo-box {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 80px;
    width: 200px;
  }
}
</style>